import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AtLeastOne, pick } from '@celum/core';
import { ExperienceProperties } from '@celum/experience/domain';
import { deviate, Result, ResultConsumerService, RetrievalOptions, schemaOf } from '@celum/ng2base';
import { PaginationOption, ResourceService } from '@celum/shared/domain';

import { AssetInformation, CONTENT_TEMPLATE, ContentTemplate, ContentTemplateStatus, Metadata } from './model/content-template.entity';
import { UploadConfirmationRequest } from './upload-logic.service';

export type ContentTemplateCreator = {
  name: string;
  attachedLibraryIds?: string[];
};

export type ConfirmContentTemplateUploadRequest = {
  uploadTicketConfirm: UploadConfirmationRequest;
  metadata: Metadata;
};

export type ContentTemplateUpdater = AtLeastOne<ContentTemplateCreator>;

export type ContentTemplateSorterKey = `name` | `modifiedAt`;
export type ContentTemplateSorter = `${ContentTemplateSorterKey}${'+' | '-'}`[];

export interface ContentTemplateFindOptions extends PaginationOption {
  sortBy?: ContentTemplateSorter;
  name?: string;
  ids?: string[];
  published?: boolean;
  statuses?: ContentTemplateStatus[];
}

export type ContentTemplateFindOneOptions = {
  id: string;
};

@Injectable({ providedIn: 'root' })
export class ContentTemplateService implements ResourceService<ContentTemplate> {
  constructor(
    private http: HttpClient,
    private consumer: ResultConsumerService
  ) {}

  public find(options?: ContentTemplateFindOptions): Observable<Result<ContentTemplate>> {
    const params = pick(options, ['offset', 'limit', 'sortBy', 'name', 'published', 'ids', 'statuses'], { skipUndefinedValues: true });
    const response$ = this.http.get(`${ExperienceProperties.properties.apiUrl}/content-templates`, { params });

    const ignoreRelatedLibrariesDeviation = deviate<ContentTemplate>(CONTENT_TEMPLATE, {
      resolveStrategy: {
        inheritStrategy: true,
        attachedLibraryIds: undefined
      }
    });

    return this.consumer
      .consume<ContentTemplate>(response$, {
        schema: [schemaOf(CONTENT_TEMPLATE)],
        resultKey: `results`,
        deviation: ignoreRelatedLibrariesDeviation
      })
      .pipe(take(1));
  }

  public create(creator: ContentTemplateCreator): Observable<ContentTemplate> {
    const response$ = this.http.post(`${ExperienceProperties.properties.apiUrl}/content-templates`, creator);
    const result$ = this.consumer.consume<ContentTemplate>(response$, { schema: schemaOf(CONTENT_TEMPLATE) });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }

  public createForImport(name: string): Observable<ContentTemplate> {
    const response$ = this.http.post(`${ExperienceProperties.properties.apiUrl}/content-templates/create-for-import`, {
      name
    });
    const result$ = this.consumer.consume<ContentTemplate>(response$, { schema: schemaOf(CONTENT_TEMPLATE) });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }

  public createSceneFromPsd(contentTemplateId: string, assetId: string): Observable<void> {
    const response$ = this.http.patch(`${ExperienceProperties.properties.apiUrl}/content-templates/${contentTemplateId}/import-psd/${assetId}`, {});
    return response$.pipe(
      map(() => void 0),
      take(1)
    );
  }

  public findOne(options: ContentTemplateFindOneOptions): RetrievalOptions<ContentTemplate> {
    const response$ = this.http.get(`${ExperienceProperties.properties.apiUrl}/content-templates/${options.id}`);
    const result$ = this.consumer.consume<ContentTemplate>(response$, { schema: schemaOf(CONTENT_TEMPLATE) }).pipe(map(value => value.entities[0]));
    return { once$: result$.pipe(take(1)), fresh$: result$ };
  }

  public update(id: string, updater?: ContentTemplateUpdater): Observable<ContentTemplate> {
    const response$ = this.http.patch(`${ExperienceProperties.properties.apiUrl}/content-templates/${id}`, updater);
    const result$ = this.consumer.consume<ContentTemplate>(response$, {
      schema: schemaOf(CONTENT_TEMPLATE)
    });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }

  public publish(id: string): Observable<ContentTemplate> {
    const response$ = this.http.post(`${ExperienceProperties.properties.apiUrl}/content-templates/${id}/publish`, {});
    const result$ = this.consumer.consume<ContentTemplate>(response$, {
      schema: schemaOf(CONTENT_TEMPLATE)
    });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }

  public unpublish(id: string): Observable<ContentTemplate> {
    const response$ = this.http.post(`${ExperienceProperties.properties.apiUrl}/content-templates/${id}/unpublish`, {});
    const result$ = this.consumer.consume<ContentTemplate>(response$, {
      schema: schemaOf(CONTENT_TEMPLATE)
    });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${ExperienceProperties.properties.apiUrl}/content-templates/${id}`);
  }

  public confirmUpload(contentTemplateId: string, upload: UploadConfirmationRequest, images: AssetInformation[]): Observable<ContentTemplate> {
    const httpRequestBody: ConfirmContentTemplateUploadRequest = {
      uploadTicketConfirm: {
        libraryContext: upload.libraryContext,
        uploadTicketId: upload.uploadTicketId,
        uploadTicketIdentifier: upload.uploadTicketIdentifier
      },
      metadata: {
        images
      }
    };

    const response$ = this.http.post(`${ExperienceProperties.properties.apiUrl}/content-templates/${contentTemplateId}/confirm-upload`, httpRequestBody);

    const result$ = this.consumer.consume<ContentTemplate>(response$, { schema: schemaOf(CONTENT_TEMPLATE) });
    return result$.pipe(
      map(({ entities }) => entities[0]),
      take(1)
    );
  }
}
