import { schema } from 'normalizr';

import { Library, LibraryService } from '@celum/libraries/api';
import { Entity, EntityRegistry, EntityTranslator, relatedEntities, RelationFunction } from '@celum/ng2base';

export const CONTENT_TEMPLATE = `ContentTemplate`;

export interface TemplateLibraryConfig {
  /** Folder id which is used to store design assets for the template. */
  assetsFolderId: string;
  /** Folder id which is used to store the import files for import a file to the template. */
  importSourceFolderId?: string;
  /** Folder id which is used to store generated previews for the template assets. */
  previewFolderId: string;
  /** Asset id which points to the scene that defines the template. There might not be a scene created so this is optional! */
  sceneAssetId?: string;
}

export enum ContentTemplateStatus {
  ACTIVE = 'ACTIVE',
  IMPORTING = 'IMPORTING'
}

export interface PreviewConfig {
  generatedFromVersion: number;
  generatedAt: string;
  id: string;
  url: string;
}

/**
 * Simple representation of an asset for storing it to the template.
 *
 * Assets are referenced in scenes via the Id of the AssetInformation and NOT the Id of the Asset itself. This decoupling helps to keep the scene more stable
 * and flexible when it comes to new requirements (e.g. referencing multiple versions of the same asset within a scene).
 */
export type AssetInformation = {
  id: string; // a unique id for an asset information instance
  libraryId: string;
  relationId: string;
  assetId: string;
  version: number;
  url: string;
};
/**
 * Additional information about the template.
 */
export type Metadata = {
  /* The images referenced in the content template. */
  images: AssetInformation[];
};

/**
 * The publication data is stored for a published template.
 */
export type PublishedData = {
  /* The version of the scene that was published. */
  sceneVersion: number;
  /* The version of the preview that was published. */
  previewVersion?: number;
  /* Additional information about the published template. */
  metadata: Metadata;
};

export interface ContentTemplate extends Entity {
  name: string;
  /* ID of the library that was created for a content template. */
  templateLibraryId: string;
  /* Additional information about the template library ({@link templateLibraryId}). */
  templateLibraryConfig: TemplateLibraryConfig;
  attachedLibraryIds: string[];
  attachedLibraries?: RelationFunction<Library[]>;
  preview?: PreviewConfig;
  /* Additional information about the template. */
  metadata: Metadata;
  /* Template publication data. Null if unpublished. */
  publishedData?: PublishedData;
  status: ContentTemplateStatus;
}

const contentTemplateTranslator: EntityTranslator<ContentTemplate> = (json: any) => ({
  id: json.id,
  typeKey: CONTENT_TEMPLATE,
  name: json.name,
  templateLibraryId: json.templateLibraryId,
  templateLibraryConfig: json.templateLibraryConfig,
  metadata: json.metadata,
  publishedData: json.publishedData,
  attachedLibraryIds: json.attachedLibraryIds,
  attachedLibraries: relatedEntities<Library>(json.id, 'attachedLibraryIds'),
  status: json.status,
  preview: json.preview
});

const contentTemplateSchema = new schema.Entity<ContentTemplate>(CONTENT_TEMPLATE);

EntityRegistry.register<ContentTemplate>({
  typeKey: CONTENT_TEMPLATE,
  translator: contentTemplateTranslator,
  schema: contentTemplateSchema,
  resolveStrategy: { attachedLibraryIds: LibraryService }
});
