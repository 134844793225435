import { filter, map, Observable, takeUntil, tap } from 'rxjs';

import { AssetLibraryType, ContentTemplate, UploadLogicService, UploadOptions } from '@celum/experience/shared';
import { ProgressStatus } from '@celum/ng2base';

export function uploadAndWaitForFinish(
  uploadLogic: UploadLogicService,
  file: File,
  contentTemplate: ContentTemplate,
  cancel$: Observable<void>
): Observable<{ assetId: string; contentTemplate: ContentTemplate }> {
  return upload(
    uploadLogic,
    file,
    {
      libraryContext: {
        libraryId: contentTemplate.templateLibraryId,
        type: AssetLibraryType.CONTENT_TEMPLATE_LIBRARY,
        contentTemplateId: contentTemplate.id
      },
      folderId: contentTemplate.templateLibraryConfig.importSourceFolderId
    },
    cancel$
  ).pipe(
    map(assetId => ({
      assetId,
      contentTemplate
    }))
  );
}

export function upload(uploadLogic: UploadLogicService, file: File, options: UploadOptions, cancel$: Observable<void>): Observable<string> {
  const uploadJobId = uploadLogic.upload([file], options);

  let tickets: string[] = [];

  // cleanup if the upload is canceled
  cancel$.subscribe(() => {
    uploadLogic.cancelRequest(uploadJobId);
    uploadLogic.clearTickets(tickets);
  });

  return uploadLogic.getUploadProgressReport(uploadJobId).pipe(
    takeUntil(cancel$),
    tap(report => (tickets = report.itemProgress.map(progress => progress.identifier))),
    filter(report => report.allFinished),
    map(report => {
      const assetIds = report.itemProgress.map(progress => progress.assetId).filter(Boolean);

      if (report.progressStatus === ProgressStatus.Success) {
        uploadLogic.clearTickets(tickets);
        return assetIds[0];
      }

      throw new Error('Upload failed');
    })
  );
}
